import React, { useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../Images/guest_posting.webp";
import logo1 from "../Images/backlinks.webp";
const Guestpost = () => {
  return (
  
      <div className="container p-0">
        <div className="row mt-5">
          <div className="col-md-1 d-none d-md-block"></div>
          <div
            className="col-12 col-md-10 p-0"
            // style={{ minHeight: "100vh", overflow: "hidden" }}
          >
            <div className="row justify-content-center mt-5">
              {/* Card 1: col-7 */}
              <div className="col-12 col-md-7 ">
                <div className="card" style={{border:"#fff", borderRadius:".4rem"}}>
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title">Backlinks</h5>
                    <p className="card-text">
                      Are links from other websites that signal trust and
                      authority, helping improve SEO rankings, drive organic
                      traffic, increase visibility, and boost your website’s
                      credibility for long-term success and growth.
                    </p>
                    <a href="">Create Backlink →</a>

                    <div className="mt-3" style={{ flexGrow: 1 }}>
                      <img
                        src={logo1}
                        alt="profile"
                        style={{
                          width: "100%", 
                          objectFit: "cover", 
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 2: col-5 */}
              <div className="col-12 col-md-5 ">
                <div className="card" style={{border:"#fff", borderRadius:".4rem"}}>
                  <div className="card-body">
                    <h5 className="card-title">Guest Posting</h5>
                    <p className="card-text">
                      Boost your reach with guest posting. Share your expertise,
                      gain quality backlinks, and attract targeted traffic to
                      grow your audience.
                    </p>
                    <a href="">Start Guest Posting →</a>
                    <div className="mt-3 " style={{ flexGrow: 1 }}>
                      <img
                        src={logo}
                        alt="profile"
                        style={{
                          width: "100%", 
                          objectFit: "cover", 
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-1 d-none d-md-block"></div>
        </div>
      </div>
  );
};

export default Guestpost;
