import React, { useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { GlobalContext } from "../Context/GlobalState";

const Domainpage = () => {
  const [url, setUrl] = useState("");
  const { setLoaderState , transactionLoader } = useContext(GlobalContext);

  console.log(setLoaderState);

  const [seoData, setSeoData] = useState(null);
  const fetchData = async () => {
    setLoaderState(true);
    try {
      const response = await fetch(
        `https://api.goseo.in/api/Seo/GetDomainAge`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ domainName: url }),
        }
      );

      const data = await response.json();
      setSeoData(data.data);
      setLoaderState(false);
    } catch (error) {
      console.error("Error fetching domain data:", error);
    }
  };

  return (
   
      <div className="container pt-0 pt-md-4">
        <h4
          style={{ color: "#fff" }}
          className="d-flex justify-content-center pt-5"
        >
          Analyze Your Website’s Authority and Age Instantly
        </h4>
        <div className="row">
          <div className="col-md-1 d-none d-md-block"></div>
          <div className="col-12 col-md-10 p-0">
            <div className="row justify-content-center mt-4 mb-5 pb-5">
              {/* Card 1: col-7 */}
              <div className="col-12 col-md-6 ">
                <div
                  className="card"
                  style={{ border: "#fff", borderRadius: ".6rem" }}
                >
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title">Domain Authority Checker</h5>
                    <p className="card-text" style={{ height: "4.5rem" }}>
                      Check the Domain Authority®, Page Authority, ranking
                      keywords, and top search competitors for a domain with a
                      single click.
                    </p>
                    <div className="position-relative" style={{}}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Domain"
                        value={URL}
                        // onChange={handleChange}
                        style={{
                          borderRadius: "50px",
                          height: "3rem",
                        }}
                      />
                      <button
                        type="submit"
                        className="btn position-absolute"
                        style={{
                          top: "50%",
                          right: "0.5rem",
                          transform: "translateY(-50%)",
                          borderRadius: "50px",
                          padding: "0.5rem 1.5rem",
                          background: "#FFCC01",
                        }}
                      >
                        Check
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 2: col-5 */}
              <div className="col-12 col-md-6 ">
                <div
                  className="card"
                  style={{ border: "#fff", borderRadius: ".6rem" }}
                >
                  <div className="card-body">
                    <h5 className="card-title">Domain Age Checker</h5>
                    <p className="card-text" style={{ height: "4.5rem" }}>
                      Domain age checker can help you determine a website’s
                      history within seconds. Just enter the URL to quickly find
                      out how long a site has been online and assess its
                      reliability!
                    </p>
                    <div className="position-relative" style={{}}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Domain"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        style={{
                          borderRadius: "50px",
                          height: "3rem",
                        }}
                      />
                      <button
                        type="submit"
                        className="btn position-absolute"
                        onClick={fetchData}
                        style={{
                          top: "50%",
                          right: "0.5rem",
                          transform: "translateY(-50%)",
                          borderRadius: "50px",
                          padding: "0.5rem 1.5rem",
                          background: "#FFCC01",
                        }}
                      >
                        Check
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {seoData && (
              <div className="col-12 mt-2 ">
                <div
                  className="card"
                  style={{ border: "#fff", borderRadius: ".6rem" }}
                >
                  <h5
                    className="pt-3 d-flex justify-content-center"
                    style={{ color: "#757d8c", textAlign: "center" }}
                  >
                    Showing results for
                  &nbsp;
                    <div style={{ color: "#f53c73", fontWeight: "bold" }}>
                      {seoData.domainName}
                    </div>
                  </h5>

                  <div className="card-body d-flex justify-content-between align-items-center">
                    <div
                      className="text-center p-2 flex-grow-1"
                      style={{ borderRight: "1px solid #cdd3d3" }}
                    >
                      <h6>Age</h6>
                      <p style={{ color: "#f53c73", fontSize: "1.2rem" }}>
                        {seoData ? seoData.estimatedDomainAge : "-"}
                      </p>
                    </div>
                    <div
                      className="text-center p-2 flex-grow-1"
                      style={{ borderRight: "1px solid #cdd3d3" }}
                    >
                      <h6>Created On</h6>
                      <p style={{ color: "#f53c73", fontSize: "1.2rem" }}>
                        {seoData ? seoData.createdDate : "-"}
                      </p>
                    </div>

                    <div
                      className="text-center p-2 flex-grow-1"
                      style={{ borderRight: "1px solid #cdd3d3" }}
                    >
                      <h6>Updated On</h6>
                      <p style={{ color: "#f53c73", fontSize: "1.2rem" }}>
                        {seoData ? seoData.updatedDate : "-"}
                      </p>
                    </div>

                    <div className="text-center p-2 flex-grow-1">
                      <h6>Expire Date</h6>
                      <p style={{ color: "#f53c73", fontSize: "1.2rem" }}>
                        {seoData ? seoData.expiresDate : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>)}
            </div>
          </div>

          <div className="col-md-1 d-none d-md-block"></div>
        </div>
      </div>
  );
};

export default Domainpage;
