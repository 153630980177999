import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../Images/logo.svg";
import Banner from "../Images/Graphics.svg";
import { Button, Container } from "react-bootstrap";
import TabWithCards from "./Slider";
import Contactus from "./Contact";
import Verticalcard from "./Verticalcard";
import Guestpost from "./Guestpost";
import Domainpage from "./Dapa";
import Seoinspect from "./Seoinspect";
import Services from "./Services";
import Tool from "./Tool";
import Trialcards from "./Trial";
import Nav from "./Navbar";
import Main from "./Main";
import Footer from "./Footer";
const First = () => {
  const [URL, setURL] = useState("");
  const sections = {
    home: useRef(null),
    Domaininspector: useRef(null),
    Contact: useRef(null),
    tool: useRef(null),
    Services: useRef(null),
    features: useRef(null),
    Attribute: useRef(null),
    Footer: useRef(null),
  };
  const handleGetStartedClick = () => {
    sections.contact.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    const observerOptions = {
      root: null,
      threshold: 0.4,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.getAttribute("data-section");
          console.log(`Intersecting: ${sectionId}`);
          window.history.replaceState(null, "", `/#${sectionId}`);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    Object.values(sections).forEach((sectionRef) => {
      if (sectionRef.current) {
        observer.observe(sectionRef.current);

        // Trigger for the initial state
        if (
          sectionRef.current.getBoundingClientRect().top < window.innerHeight
        ) {
          const sectionId = sectionRef.current.getAttribute("data-section");
          window.history.replaceState(null, "", `/#${sectionId}`);
        }
      }
    });

    return () => {
      Object.values(sections).forEach((sectionRef) => {
        if (sectionRef.current) observer.unobserve(sectionRef.current);
      });
    };
  }, [sections]);
  const [seoData, setSeoData] = useState(null);
  const fetchData = async (url) => {
    try {
      const response = await fetch(`https://api.goseo.in/api/Seo/GetSeoScore`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch SEO data.");
      }
      const data = await response.json();
      setSeoData(data);
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  const handleAnalyzeClick = () => {
    if (URL.trim() === "") {
      alert("Please enter a valid URL.");
      return;
    }
    fetchData(URL);
  };
  return (
    <Container fluid className="p-0" style={{ overflowX: "hidden" }}>
      {/* home */}

      <div
        ref={sections.home}
        data-section="home"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="d-flex justify-content-center"
          style={{ background: "#fdca40" }}
        >
          <p className="p-2 m-0 text-center">
            Discover What <b>GoSeo</b> Can Do for You — Book Your Demo Today!
          </p>
        </div>
        <Nav />
        {/* seo inspector  */}
        {/* <Main/> */}

        <div
          className="p-0 mt-3 m-0"
          style={{
            background:
              "linear-gradient(255.53deg, #468ED3 -16.75%, #2176FF 66.41%, #344BFD 91.25%)",
            maxWidth: "100vw",
          }}
        >
          <div className="row  p-5">
            <div className="col-md-1 d-none d-md-block"></div>
            <div
              className="col-12 col-md-10 p-0"
              // style={{ minHeight: "100vh", overflow: "hidden" }}
            >
              <div className="row justify-content-center ">
                {/* Card 1: col-7 */}
                <div className="col-12 col-md-6 flex-column d-flex justify-content-center ">
                  <h1 style={{ color: "#fff" }}>SEO Inspector</h1>
                  <p style={{ maxWidth: "500px", color: "#fff" }}>
                    Analyze your website’s SEO performance in detail with GoSEO.
                    Identify optimization gaps, check page performance, and get
                    actionable insights to improve rankings and traffic.
                  </p>
                  <div
                    className="position-relative"
                    style={{
                      width: "30vw",
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter URL e.g. https://google.com"
                      value={URL}
                      onChange={(e) => setURL(e.target.value)}
                      style={{
                        borderRadius: "50px",
                        height: "3rem",
                      }}
                    />
                    <button
                      type="submit"
                      className="btn position-absolute"
                      style={{
                        top: "50%",
                        right: "0.5rem",
                        transform: "translateY(-50%)",
                        borderRadius: "50px",
                        // padding: "0.5rem 1.5rem",
                        background: "#FFCC01",
                      }}
                      onClick={handleAnalyzeClick}
                    >
                      ANALYZE
                    </button>
                  </div>
                </div>

                {/* Card 2: col-5 */}
                <div className="col-12 col-md-6 d-flex justify-content-end">
                  <img
                    src={Banner}
                    alt="Banner"
                    style={{
                      height: "55vh",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-1 d-none d-md-block"></div>
          </div>
        </div>
        <div className="container mb-3 p-0">
          <div className="row mt-3 ">
            <div className="col-md-1 d-none d-md-block"></div>
            <div
              className="col-12 col-md-10 p-0"
              // style={{ minHeight: "100vh", overflow: "hidden" }}
            >
              <Seoinspect seoData={seoData} />
            </div>

            <div className="col-md-1 d-none d-md-block"></div>
          </div>
        </div>
        {!seoData && (
          <div className="mt-2 mb-3">
            <p
              className="text-center"
              style={{ fontSize: "1.2rem", fontWeight: "600" }}
            >
              Rank Higher, Capture Visitors, and Boost Conversions with GoSEO
              <br /> Your All-in-One SEO Solution.
            </p>
          </div>
        )}
      </div>
      {!seoData && (
        <>
          {/* Domain inspector */}
          <div
            ref={sections.Domaininspector}
            data-section="Domaininspector"
            className="m-0 p-0"
            style={{
              minHeight: "100vh",
              overflow: "hidden",
              background: "#EBEBEB",
            }}
          >
            <Guestpost />
          </div>
          <div
            className="m-0 p-0"
            style={{
              background: "#31393C",
            }}
          >
            <Domainpage />
          </div>
          {/* Contactus */}
          <div
            ref={sections.Contact}
            data-section="Contact"
            className="m-0 p-0"
            style={{ minHeight: "100vh", overflow: "hidden" }}
          >
            <Contactus />
          </div>
          {/* tool */}
          <div
            ref={sections.tool}
            data-section="tool"
            style={{ background: "#31393C" }}
          >
            <div
              className="container"
              style={{
                background: "#31393C",
                minHeight: "100vh",
                overflow: "hidden",
              }}
            >
              <div className="row pt-3 pb-5">
                <div className="col-md-1 d-none d-md-block"></div>
                <div className="col-12 col-md-10 p-0">
                  <Tool />
                </div>

                <div className="col-md-1 d-none d-md-block"></div>
              </div>
            </div>
          </div>
          {/* <div
            ref={sections.tool}
            data-section="tool"
            style={{ background: "#31393C", minHeight: "100vh" }}
          >
            <Tool />
          </div> */}
          {/* Services */}
          <div
            ref={sections.Services}
            data-section="Services"
            style={{ background: "#EBEBEB" }}
          >
            <div
              className="container"
              style={{
                background: "#EBEBEB",
                minHeight: "100vh",
                overflow: "hidden",
              }}
            >
              <div className="row pt-3 pb-5">
                <div className="col-md-1 d-none d-md-block"></div>
                <div
                  className="col-12 col-md-10 p-0"
                  // style={{ minHeight: "100vh", overflow: "hidden" }}
                >
                  <Trialcards />
                </div>

                <div className="col-md-1 d-none d-md-block"></div>
              </div>
            </div>
          </div>

          {/* <div
            ref={sections.Services}
            data-section="Services"
            style={{
              background: "#EBEBEB",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <Services />
          </div> */}
          {/* features */}
          <div
            ref={sections.features}
            data-section="features"
            className="m-0 p-0"
            style={{
              background: "#FDCA40",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <h2
              className="text-center mt-3 p-5 "
              style={{
                fontWeight: "600",
              }}
            >
              Explore the Power of{" "}
              <span style={{ color: "#2176FF" }}>GoSEO’s</span> Features
            </h2>

            <div className="container p-0">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col">
                  <TabWithCards />
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </div>
          {/* Attribute */}
          <div
            ref={sections.Attribute}
            data-section="Attribute"
            className="m-0 p-0"
            style={{ background: "#fff", height: "100vh", overflow: "hidden" }}
          >
            <h2
              className="text-center mt-3 p-4 "
              style={{
                fontWeight: "600",
              }}
            >
              Explore the Power of GoSEO’s Features
            </h2>
            <div className="container p-0">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col">
                  <Verticalcard />
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        ref={sections.Footer}
        data-section="Footer"
        className="m-0 p-0"
        style={{
          overflow: "hidden",
          background: "#31393C",
          borderTopRightRadius: "2rem",
          borderTopLeftRadius: "2rem",
        }}
      >
        <Footer />
      </div>
    </Container>
  );
};

export default First;
