import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import cardimg6 from "../Images/Coderides.svg";
import cardimg1 from "../Images/SiteMaps.svg";
import cardimg2 from "../Images/AIPower.svg";
import cardimg3 from "../Images/Schemagenerate.svg";
const VerticalCard = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [hoveredTab, setHoveredTab] = useState(null);

  const tabNames = [
    "01 Instant Impact with Real-Time Code Overrides",
    "02 Visibility Elevated with Dynamic XML Sitemaps",
    "03 Intelligence at Your Fingertips with AI-Powered Insights",
    "04 Authority Amplified with Schema Markup Generator",
  ];

  const tabDescriptions = [
    "Make on-the-fly changes to critical SEO elements like meta tags and structured data, ensuring your website stays agile and search-engine-ready at all times.",
    "Automatically notify search engines of updates, securing faster indexing and keeping your site ahead in the crawl race.",
    "Leverage actionable recommendations crafted by AI to optimize your content and strategies for unmatched performance.",
    "Enhance your content's visibility with structured data, earning rich snippets that draw attention and boost click-through rates.",
  ];

  const tabCards = {
    tab1: [
      {
        id: 1,
        title: "Card 1 for Direct JSON Changes",
        content: "Content for card 1 on Direct JSON Changes",
        image: (
          <img
            className="img-fluid"
            src={cardimg6}
            alt="Direct JSON Changes"
            style={{ borderRadius: "0.5rem" }}
          />
        ),
      },
    ],
    tab2: [
      {
        id: 1,
        title: "Card 1 for Real-Time Code Overrides",
        content: "Content for card 1 on Real-Time Code Overrides",
        image: (
          <img
            className="img-fluid"
            src={cardimg1}
            alt="Direct JSON Changes"
            style={{ borderRadius: "0.5rem" }}
          />
        ),
      },
    ],
    tab3: [
      {
        id: 1,
        title: "Card 1 for Speed Optimization",
        content: "Content for card 1 on Speed Optimization",
        image: (
          <img
            className="img-fluid"
            src={cardimg2}
            alt="Direct JSON Changes"
            style={{ borderRadius: "0.5rem" }}
          />
        ),
      },
    ],
    tab4: [
      {
        id: 1,
        title: "Card 1 for Dynamic Schema and Sitemaps",
        content: "Content for card 1 on Dynamic Schema and Sitemaps",
        image: (
          <img
            className="img-fluid"
            src={cardimg3}
            alt="Direct JSON Changes"
            style={{ borderRadius: "0.5rem" }}
          />
        ),
      },
    ],
  };

  const handleMouseEnter = (tab, index) => {
    setActiveTab(tab);
    setHoveredTab(index);
  };

  const handleMouseLeave = () => {
    setHoveredTab(null);
  };

  return (
    <div className="container ">
      <div className="row">
        {/* Tabs */}
        <div className="col-md-6 py-5">
          <div className="list-group custom-tabs">
            {tabNames.map((name, index) => (
              <div
                key={`tab${index + 1}`}
                className="custom-tab-wrapper"
                onMouseEnter={() => handleMouseEnter(`tab${index + 1}`, index)}
                onMouseLeave={handleMouseLeave}
              >
                <button
                  className={`list-group-item custom-tab ${
                    activeTab === `tab${index + 1}` ? "active-tab" : ""
                  }`}
                >
                  {name}
                </button>
                {hoveredTab === index && (
                  <p className="tab-description">{tabDescriptions[index]}</p>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Content */}
        <div className="col-md-6">
          <div
            className="content-wrapper"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              height: "100%",
              overflow: "hidden",
            }}
          >
            {tabCards[activeTab].map((card) => (
              <div key={card.id} className="image-container">
                {card.image}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalCard;
