import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import icon1 from "../Images/cardicon1.svg";
import icon2 from "../Images/cardicon2.svg";
import icon3 from "../Images/cardicon3.svg";
import icon4 from "../Images/cardicon4.svg";
import cardimg1 from "../Images/cardimg1.svg";
import cardimg2 from "../Images/cardimg2.svg";
import cardimg3 from "../Images/cardimg3.svg";
import cardimg4 from "../Images/cardimg4.svg";
import cardimg5 from "../Images/cardimg5.svg";

const Trialcards = () => {
  return (
    <Container fluid className="p-0" style={{ overflowX: "hidden" }}>
      <div data-section="services">
        <h2 className="text-center fw-bold pt-3">Comprehensive SEO Services</h2>
        <p
          className="text-center mx-auto "
          style={{
            fontSize: "1rem",
            maxWidth: "90%",
            lineHeight: "1.5",
          }}
        >
          Optimize your online presence with GoSEO. Boost visibility, drive
          traffic, and improve
          <br /> rankings to achieve your business goals and long-term growth.
        </p>

        <div className="container p-0">
          <div className="row m-0 p-0">
            {/** First Row of Cards */}
            <div className="col-12 col-md-4 p-2">
              <div className="card h-100 px-3 pt-3 ">
                <div className="card-body p-0">
                  <img
                    src={icon1}
                    alt="Search Engine Optimization"
                    className="p-2"
                    style={{
                      height: "7vh",
                      background: "#EDEDED",
                      borderRadius: ".3rem",
                    }}
                  />
                  <h5 className="mt-2">Search Engine Optimization</h5>
                  <p className="text-muted" style={{ fontSize: ".9rem" }}>
                    Enhance rankings and visibility with GoSEO’s all-in-one SEO
                    toolkit, targeting on-page and off-page factors.
                  </p>
                  <img
                    src={cardimg1}
                    alt="SEO"
                    className="img-fluid p-0 m-0"
                    style={{ maxHeight: "200px", objectFit: "contain" }}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4 p-2">
              <div className="card h-100 px-3 pt-3 ">
                <div className="card-body p-0">
                  <img
                    src={icon2}
                    alt="Social Media Optimization"
                    className="p-2"
                    style={{
                      height: "7vh",
                      background: "#EDEDED",
                      borderRadius: ".3rem",
                    }}
                  />
                  <h5 className="mt-2">Social Media Optimization</h5>
                  <p className="text-muted" style={{ fontSize: ".9rem" }}>
                    Maximize your brand's social media reach to drive traffic
                    and enhance your SEO strategy.
                  </p>
                  <img
                    src={cardimg2}
                    alt="Social Media Optimization"
                    className="img-fluid m-0 p-0"
                    style={{ maxHeight: "200px", objectFit: "contain" }}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4 p-2">
              <div className="card h-100 px-3 pt-3 ">
                <div className="card-body p-0">
                  <img
                    src={icon3}
                    alt="App Store Optimization"
                    className="p-2"
                    style={{
                      height: "7vh",
                      background: "#EDEDED",
                      borderRadius: ".3rem",
                    }}
                  />
                  <h5 className="mt-3">App Store Optimization</h5>
                  <p className="text-muted" style={{ fontSize: ".9rem" }}>
                    Optimize your app’s presence on app stores, improving
                    discoverability and driving downloads.
                  </p>
                  <img
                    src={cardimg3}
                    alt="App Store Optimization"
                    className="img-fluid mt-3"
                    style={{ maxHeight: "200px", objectFit: "contain" }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/** Second Row of Cards */}
          <div className="row m-0 p-0">
            <div className="col-12 col-md-6 p-2">
              <div className="card h-100 p-2">
                <div className="card-body ">
                  <div className="d-flex flex-column flex-md-row align-items-center">
                    <div>
                      <img
                        src={icon4}
                        alt="SEO Analysis"
                        className="p-2"
                        style={{
                          height: "7vh",
                          background: "#EDEDED",
                          borderRadius: ".3rem",
                        }}
                      />
                      <h5 className="mt-3">SEO Analysis</h5>
                      <p className="text-muted" style={{ fontSize: ".9rem" }}>
                        Get a detailed analysis of your website’s SEO
                        performance and actionable insights to guide continuous
                        improvement.
                      </p>
                    </div>
                    <img
                      src={cardimg4}
                      alt="SEO Analysis"
                      className="img-fluid mt-3"
                      style={{ maxHeight: "200px", objectFit: "contain" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 p-2">
              <div className="card h-100 p-2">
                <div className="card-body">
                  <div className="d-flex flex-column flex-md-row align-items-center">
                    <div>
                      <img
                        src={icon4}
                        alt="Campaign Management"
                        className="p-2"
                        style={{
                          height: "7vh",
                          background: "#EDEDED",
                          borderRadius: ".3rem",
                        }}
                      />
                      <h5 className="mt-3">Campaign Management</h5>
                      <p className="text-muted" style={{ fontSize: ".9rem" }}>
                        Plan, execute, and track digital campaigns directly from
                        GoSEO, helping you manage marketing efforts with ease.
                      </p>
                    </div>
                    <img
                      src={cardimg5}
                      alt="Campaign Management"
                      className="img-fluid mt-3"
                      style={{ maxHeight: "200px", objectFit: "contain" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Trialcards;
