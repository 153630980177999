import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../Images/logo.svg";
import { Button, Container } from "react-bootstrap";

const Nav = () => {
  const handleGetStartedClick = () => {
    // sections.contact.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      
      {/* <Container className="">
        <div className="row mt-3">
          <div className="col-md-1"></div>
          <div className="col-10 p-0 d-flex justify-content-between align-items-center">
            <img src={logo} alt="profile" style={{ height: "5vh" }} />
            <div>
              <Button
                size="sm"
                style={{ borderRadius: "1.5rem", marginRight: "10px" }}
                onClick={handleGetStartedClick}
              >
                Get Started
              </Button>
              <Button
                size="sm"
                variant="secondary"
                style={{ borderRadius: "1.5rem", marginRight: "10px" }}
                onClick={() =>
                  window.open("https://dashboard.goseo.in/", "_blank")
                }
              >
                Login
              </Button>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>

        <hr className="mb-0" />
      </Container> */}

      <div className="container p-0">
        <div className="row m-0 p-0">
          <div className="col-md-1 d-none d-md-block"></div>
          <div
            className="col-12 col-md-10 p-0"
            // style={{ minHeight: "100vh", overflow: "hidden" }}
          >
            <div className="row  mt-3">
              {/* Card 1: col-7 */}
              <div className="col-12 col-md-7 ">
              <img src={logo} alt="profile" style={{ height: "5vh" }} />
         
              </div>

              {/* Card 2: col-5 */}
              <div className="col-12 p-0 col-md-5 justify-content-end d-flex">
              <div>
              <Button
                size="sm"
                style={{ borderRadius: "1.5rem", marginRight: "10px" }}
                onClick={handleGetStartedClick}
              >
                Get Started
              </Button>
              <Button
                size="sm"
                variant="secondary"
                style={{ borderRadius: "1.5rem", marginRight: "10px" }}
                onClick={() =>
                  window.open("https://dashboard.goseo.in/", "_blank")
                }
              >
                Login
              </Button>
            </div>
              </div>
            </div>
          </div>

          <div className="col-md-1 d-none d-md-block"></div>
        </div>

      </div>
    </>
  );
};

export default Nav;
