import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TransactionLoader from "./Utils/Transactionloader";
import { GlobalContext, GlobalProvider } from "./Context/GlobalState";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <GlobalProvider>
      <GlobalContext.Consumer>
        {(context) => (
          <TransactionLoader
            text="Please Wait..."
            isActive={context.transactionLoader}
          >
            <App />
          </TransactionLoader>
        )}
      </GlobalContext.Consumer>
    </GlobalProvider>
  </React.StrictMode>
);

reportWebVitals();
