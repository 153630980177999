import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";
const user = JSON.parse(sessionStorage.getItem("user"));
const initialState = {
  transactionLoader: false,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);


  const setTransactionLoaderState = (loaderState) =>{
    dispatch({
      type: "SET_TRANSACTION_LOADER",
      payload: loaderState,
    });
  }
  return (
    <GlobalContext.Provider
      value={{
        transactionLoader : state.transactionLoader,
        setLoaderState : setTransactionLoaderState,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
