import React, { useState } from "react";
import { Tabs, Tab, Card } from "react-bootstrap";
import icon1 from "../Images/cardicon5.svg";
import SEO from "../Images/SEO.svg";
import SMO from "../Images/SMO.svg";
import Campaign from "../Images/Campaign.svg";
import Keywords from "../Images/Keywords.svg";
import Analysis from "../Images/Analysis.svg";
import leftarrow from "../Images/left_arrow.svg";
import rightarrow from "../Images/right_arrow.svg";
import ASO from "../Images/ASO.svg";
const TabWithCards = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const tabCards = {
    tab1: [
      {
        id: 1,
        title: "Search Engine Optimization",
        content:
          "GO SEO’s powerful tools take the guesswork out of search engine rankings. Our all-in-one solution tackles every SEO need—from optimizing your site’s structure to crafting keyword-rich content—so you appear where it matters most. Get ready to watch your organic traffic soar as we drive the right audience straight to you.",
      },
    ],
    tab2: [
      {
        id: 1,
        title: "Social Media Optimization",
        content:
          "Social media is where connections happen, and GO SEO helps you make the most of it. Our SMO tools empower you to optimize every post, image, and update, turning casual followers into engaged customers. With GO SEO, your brand stands out on every platform, building relationships that go beyond the ‘like’ button.",
      },
    ],
    tab3: [
      {
        id: 1,
        title: "App Store Optimization",
        content:
          "Ready to boost your app downloads? GO SEO ensures your app stands out in crowded app stores. We optimize every detail, from keywords to descriptions, helping potential users discover and download your app easily. With GO SEO, your app’s visibility grows, and so does your user base.",
      },
    ],
    tab4: [
      {
        id: 1,
        title: "Keyword Research",
        content:
          "Connect with your audience by speaking their language. GO SEO’s keyword research dives deep to uncover the phrases that matter most. With our data-driven insights, you’ll create content that resonates, driving meaningful engagement and growth.",
      },
    ],
    tab5: [
      {
        id: 1,
        title: "SEO Analysis and Reports",
        content:
          "Stay in the know with GO SEO’s advanced analytics and reporting. Get a clear view of what’s working and what could use a tweak, empowering you to make data-driven decisions. Our easy-to-read reports are your roadmap to continuous improvement and lasting SEO success.",
      },
    ],
    tab6: [
      {
        id: 1,
        title: "Campaign Management",
        content:
          "Managing campaigns has never been this seamless. GO SEO gives you the tools to plan, track, and optimize every campaign—all in one place. From ad campaigns to content pushes, we help you reach your audience with the right message at the right time, maximizing your impact.",
      },
    ],
  };
  const tabImages = {
    tab1: SEO,
    tab2: SMO,
    tab3: ASO,
    tab4: Keywords, 
    tab5: Analysis, 
    tab6: Campaign, 
  };
  const goToPreviousTab = () => {
    const tabKeys = Object.keys(tabCards);
    const currentIndex = tabKeys.indexOf(activeTab);
    const newIndex = currentIndex === 0 ? tabKeys.length - 1 : currentIndex - 1;
    setActiveTab(tabKeys[newIndex]);
  };
  const goToNextTab = () => {
    const tabKeys = Object.keys(tabCards);
    const currentIndex = tabKeys.indexOf(activeTab);
    const newIndex = currentIndex === tabKeys.length - 1 ? 0 : currentIndex + 1;
    setActiveTab(tabKeys[newIndex]);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-4">
          <div
            style={{ background: "#fff", borderRadius: ".6rem" }}
          >
        <img
              src={tabImages[activeTab]}
              alt={`Image for ${activeTab}`}
              style={{ width: "100%", height: "auto", borderRadius: ".6rem" }}
            />
          </div>
        </div>
        <div
          className="col "
          style={{ background: "#fff", borderRadius: ".6rem" }}
        >
          <Tabs
            activeKey={activeTab}
            onSelect={(tab) => setActiveTab(tab)}
            className="mb-3 slider-tabs full-width-tabs"
            style={{ border: "none", height:"vh" }}
          >
            <Tab eventKey="tab1" title="SEO" />
            <Tab eventKey="tab2" title="SMO" />
            <Tab eventKey="tab3" title="ASO" />
            <Tab eventKey="tab4" title="Keyword" />
            <Tab eventKey="tab5" title="Analysis" />
            <Tab eventKey="tab6" title="Campaign" />
          </Tabs>

          <div className="row">
            <div className="">
              {tabCards[activeTab].map((card) => (
                <div className=" m-3">
                  <img
                    src={icon1}
                    className="p-2"
                    alt="profile"
                    style={{
                      height: "7vh",
                      background: "#DDE6FA",
                      borderRadius: ".3rem",
                    }}
                  />
                  <h4 className="mt-4 mb-3">{card.title}</h4>
                  <p
                    className="mb-5"
                    style={{ color: "#1C1C1CB2", fontSize: ".9rem", height:"15vh" }}
                  >
                    {card.content}
                  </p>
                  <div className="d-flex justify-content-end">
                    <img
                      src={leftarrow}
                      alt="left-arrow"
                      onClick={goToPreviousTab}
                      style={{
                        height: "5vh",
                        background: "#DDE6FA",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    /> &nbsp;&nbsp;
                    <img
                      src={rightarrow}
                      alt="right-arrow"
                      onClick={goToNextTab}
                      style={{
                        height: "5vh",
                        background: "#DDE6FA",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>

              ))}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default TabWithCards;
