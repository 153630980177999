import React, { useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Banner3 from "../Images/banner3.svg";
const Tool = () => {
  return (
    <>
      <p
        className="text-center "
        style={{
          fontSize: "1.2rem",
          fontWeight: "600",
          color: "#fff",
          paddingTop: "5rem",
        }}
      >
        A Powerful Suite of Tools to Maximize Your SEO Success.
      </p>
      <div className="row mt-5 mx-0 align-items-center">
        {/* Left Section: Image */}
        <div className="col-12 col-md-6 ">
          <img
            src={Banner3}
            alt="Banner"
            style={{
              height: "auto",
              maxHeight: "60vh",
              // width: "100%",
              objectFit: "contain",
            }}
            className="img-fluid"
          />
        </div>

        {/* Right Section: Text */}
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center px-4">
          <h2 className="text-white text-center text-md-start mb-3">
            How GoSEO Works
          </h2>
          <p
            style={{ maxWidth: "500px", color: "#fff" }}
            className="mx-auto mx-md-0 text-center text-md-start"
          >
            Why settle for basic, surface-level SEO tweaks when you can dive
            deeper? Our tool allows you to control every SEO parameter directly
            from the core – through JSON and code.
          </p>
          <p
            style={{ maxWidth: "500px", color: "#fff" }}
            className="mx-auto mx-md-0 text-center text-md-start"
          >
            Say goodbye to cookie-cutter SEO strategies and hello to advanced
            customization that adapts to your site’s unique needs.
          </p>
          <p
            style={{ maxWidth: "500px", color: "#fff" }}
            className="mx-auto mx-md-0 text-center text-md-start"
          >
            Whether you're optimizing metadata, enhancing load speeds, managing
            indexability, or crafting precise sitemaps, our platform gives you
            the direct access you've been missing.
          </p>
        </div>
      </div>
    </>
  );
};

export default Tool;
