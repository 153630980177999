import React from "react";

export default (state, action) => {
  switch (action.type) {
  
    case "SET_TRANSACTION_LOADER":
      return { ...state, transactionLoader: action.payload };
  
        return { ...state, isVerified: action.payload }; 
    default:
      return state;
  }
};
