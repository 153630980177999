import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import F1 from "../Images/footerlogo.svg";
import F2 from "../Images/instafooter.svg";
import F3 from "../Images/footerfacebook.svg";
import F4 from "../Images/footertwit.svg";

const Footer = () => {
  return (
    <>
      {/* footer */}
      <div className="container p-0">
        <div className="mt-md-5 mt-3">
          <div className="row  mx-md-0 mx-3 mt-md-5 mt-sm-3 py-4">
            <div className="col-md-1 "></div>
            <div className="col-md-4 col p-0 ">
              <a to="/">
                <img
                  className="mb-3"
                  style={{ height: "1.7rem" }}
                  src={F1}
                  alt="Billpunch"
                />
              </a>

              <p style={{ color: "#fff" }}>
              GoSEO is an SEO platform that boosts online visibility<br/> and search engine rankings through optimization<br/> tools and strategies.
              </p>
            </div>
            <div className="col ">
              <a to="/privacy_policy">
                {" "}
                <p style={{ color: "#fff" }}>Privacy </p>
              </a>
              <a to="/refund_policy">
                {" "}
                <p style={{ color: "#fff" }}>Pricing </p>
              </a>
              <a to="/terms_condition">
                {" "}
                <p style={{ color: "#fff" }}>Terms & Conditions </p>
              </a>
              <a to="/faq">
                {" "}
                <p style={{ color: "#fff" }}>FAQ</p>
              </a>
            </div>
            <div className="col ">
              <a to="/contact_us">
                <p style={{ color: "#fff" }}>Blog </p>
              </a>
              <a to="/faq">
                {" "}
                <p style={{ color: "#fff" }}>Contact us</p>
              </a>
            </div>

            <div
              className="col 
          "
            >
              <h6 className="mt-1 mx-md-2" style={{ color: "#fff" }}>
                Follow US
              </h6>
              <div className="d-flex align-items-start justify-content-evenly">
                <a target="_blank" rel="noopener noreferrer">
                  <img
                    className="insta m-1"
                    alt="Billpunch_Instagram"
                    style={{ height: "3rem", cursor: "pointer" }}
                    src={F2}
                  />
                </a>
                <a target="_blank" rel="noopener noreferrer">
                  <img
                    className="facebook m-1"
                    alt="Billpunch_facebook"
                    style={{ height: "3rem" }}
                    src={F3}
                  />
                </a>

                <a target="_blank" rel="noopener noreferrer">
                  <img
                    className="adin m-1"
                    alt="Billpunch_adin"
                    style={{ height: "3rem" }}
                    src={F4}
                  />
                </a>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
          <p
          className="text-center mx-auto "
          style={{
            fontSize: "1rem",
            maxWidth: "90%",
            lineHeight: "1.5",color:"#fff"
          }}
        >
       Ithum Tower, Tower-B, 218, Second Floor,Sector 62 Noida-201309
        </p>
        <hr className="px-5" style={{color:"#fff"}}/>
        </div>
      </div>
    </>
  );
};

export default Footer;
